var $ = require('jquery');
var Notification = require('notification');
var Events = require('events');

module.exports = new function () {

    var _this = this;

    Events.help(this);

    // this.getBase = function (name, callback) {
    //     _this.sendData('/base/' + name, 'GET', null, callback, true);
    // }

    this.getForm = function (name, callback) {
        //console.log(name);
        if (Array.isArray(name)) name = name.join('.');
        _this.sendData('/form/' + name, 'GET', null, callback, true);
    }

    this.sendData = function (url, type, data, callback, unauthorized, errMessage) {
        //console.log('send:' + url);
        if (!_token && !unauthorized) {
            console.log('Login required for remote request: ' + type + ', ' + url);
            return;
        }
        var xhr = new XMLHttpRequest();
        xhr.open(type, url);
        xhr.setRequestHeader('Content-Type', 'application/json');
        if (!unauthorized) {
            xhr.setRequestHeader('Authorization', _token);
        }

        xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
                if (xhr.status == 200) {
                    if (callback) {
                        var ans = {};
                        try {
                            ans = JSON.parse(xhr.responseText);
                        }
                        catch (err) {
                        }
                        finally {
                            callback(ans);
                        }
                    }
                }
                else if (xhr.responseText == 'Forbidden') {
                    var message = 'Invalid username or password';
                    Notification.error(message);
                } else {
                    var errCode = xhr.responseText.split(':')[1];
                    if (errCode) errCode = errCode.trim();
                    var message = 'There was an error performing your request';
                    if (typeof errMessage == 'object')
                        message = errMessage[errCode] || errMessage.else;
                    Notification.error(message);
                    console.log(url);
                    console.log(xhr.responseText);
                }
            }
        }

        if (data) {
            //if (_id) data.byId = _id;
            if (_user) data.byUser = _user;
            if (_name) data.byName = _name;
            xhr.send(JSON.stringify(data));
        }
        else xhr.send();
    }

    this.sendFile = function (bkt, file, callback, errMessage) {
        var xhr = new XMLHttpRequest();

        xhr.open("POST", '/file/' + bkt, true);
        xhr.setRequestHeader('Authorization', _token);
        xhr.setRequestHeader('Content-Disposition', 'filename=' + file.name);
        xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
                if (xhr.status == 200) {
                    console.log(xhr.responseText);
                    if (callback) callback(JSON.parse(xhr.responseText)._id);
                }
                else {
                    var message;
                    if (typeof errMessage == 'object' && errMessage !== null) {
                        message = errMessage[xhr.status] || errMessage.else;
                    } else {
                        message = errMessage ? errMessage : 'There was an error performing your request';
                    }
                    Notification.error(message);
                    console.log(xhr.responseText);
                }
            }
        };
        xhr.send(file);
    }

    var _id;
    var _user;
    var _name;
    var _token;
    var _routes;

    this.name = function () { return _name; }

    this.getRoutes = function (lst) {
        if (!lst || lst == 'all')
            return _routes;

        var rtn = [];
        for (var i = 0; i < _routes.length; i++) {
            if (lst.indexOf(_routes[i].route) > -1)
                rtn.push(_routes[i]);
        }
        return rtn;
    }

    this.getToken = function () {
        return _token;
    }

    this.setToken = function (token) {
        _token = token
    }
    
    this.check = function (data, callback) {
        _this.sendData('/check', 'POST', data, function (res) {
            callback(res);
        }, true, 'Error logging in. Please check username and password.');
    }

    this.checkPatient = function (data, callback) {
        _this.sendData('/patient/check/' + data.facility + '/' + data.patientId, 'GET', data, function (res) {
            callback(res);
        }, true, 'Error checking patient.');
    }

    var _refreshTimeout;
    this.login = function (data, callback) {
        _this.sendData('/login', 'POST', data, function (res) {
            _id = res.id;
            _user = res.username;
            _name = res.name;
            _token = res.token;
            _routes = res.routes;
            callback(res);
        }, true, 'Error logging in. Please check username and password.');
    }

    this.logout = function () {
        _this.trigger('login', false);
        _token = null;
    }

    function decodeToken() {
        var parts = _token.split('.');
        return JSON.parse(atob(parts[1]));
    }
    this.decodeToken = decodeToken;

    function setToken(res) {
        _token = res.token;
        //_this.trigger('login', true);
        var payload = decodeToken();
        var now = Date.now();
        _refreshTimeout = setTimeout(refreshToken, .8 * (payload.exp - now));
    }

    function refreshToken() {
        console.log('refreshing token');
        _this.get('/refresh', setToken, 'There was an error authorizing with the server');
    }

    this.allFacilities = function (callback) {
        _this.sendData('/facilities', 'GET', null, callback, true);
    }

    this.post = function (url, data, callback, errMessage) {
        _this.sendData(url, 'POST', data, callback, false, errMessage);
    }

    this.put = function (url, data, callback, errMessage) {
        _this.sendData(url, 'PUT', data, callback, false, errMessage);
    }

    this.delete = function (url, callback, errMessage) {
        _this.sendData(url, 'DELETE', null, callback, false, errMessage);
    }

    this.get = function (url, callback, errMessage) {
        _this.sendData(url, 'GET', null, callback, false, errMessage);
    }
}