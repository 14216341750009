var Diver = require('diver');
var Router = require('router');
var Design = require('design');

var frontAreas = [];
frontAreas['m'] = [
    { opt: 'Head', val: 'F_HEAD', top: 7, left: 140, width: 70, height: 95 },
    { opt: 'Chest', val: 'F_CHEST', top: 102, left: 135, width: 80, height: 80 },
    { opt: 'Stomach', val: 'F_STOMACH', top: 182, left: 125, width: 100, height: 95 },
    { opt: 'Groin', val: 'F_GROIN', top: 277, left: 115, width: 125, height: 70 },
    { opt: 'Upper Legs', val: 'F_UPPER_LEGS', top: 347, left: 115, width: 125, height: 90 },
    { opt: 'Knees', val: 'F_KNEES', top: 437, left: 115, width: 125, height: 60 },
    { opt: 'Shins', val: 'F_SHINS', top: 497, left: 115, width: 125, height: 95 },
    //        { opt: 'Ankles', val: 'F_ANKLES', top: 602, left: 115, width: 125, height: 25 },
    { opt: 'Feet', val: 'F_FEET', top: 592, left: 115, width: 125, height: 55 },

    { opt: 'R Shoulder', val: 'RF_SHOULDER', top: 102, left: 75, width: 60, height: 80 },
    { opt: 'L Shoulder', val: 'LF_SHOULDER', top: 102, left: 215, width: 60, height: 80 },
    { opt: 'R Arm', val: 'RF_ARM', top: 182, left: 30, width: 75, height: 110 },
    { opt: 'L Arm', val: 'LF_ARM', top: 182, left: 250, width: 75, height: 110 },
    { opt: 'R Wrist', val: 'RF_WRIST', top: 292, left: 10, width: 40, height: 20 },
    { opt: 'L Wrist', val: 'LF_WRIST', top: 292, left: 300, width: 40, height: 20 },
    { opt: 'R Hand', val: 'RF_HAND', top: 312, left: 0, width: 60, height: 70 },
    { opt: 'L Hand', val: 'LF_HAND', top: 312, left: 290, width: 60, height: 70 }
];
frontAreas['f'] = [
    { opt: 'Head', val: 'F_HEAD', top: 7, left: 140, width: 70, height: 95 },
    { opt: 'Chest', val: 'F_CHEST', top: 102, left: 140, width: 80, height: 85 },
    { opt: 'Stomach', val: 'F_STOMACH', top: 187, left: 125, width: 100, height: 95 },
    { opt: 'Groin', val: 'F_GROIN', top: 282, left: 115, width: 125, height: 70 },
    { opt: 'Upper Legs', val: 'F_UPPER_LEGS', top: 352, left: 115, width: 125, height: 70 },
    { opt: 'Knees', val: 'F_KNEES', top: 422, left: 115, width: 125, height: 60 },
    { opt: 'Shins', val: 'F_SHINS', top: 482, left: 115, width: 125, height: 110 },
    //        { opt: 'Ankles', val: 'F_ANKLES', top: 602, left: 115, width: 125, height: 25 },
    { opt: 'Feet', val: 'F_FEET', top: 592, left: 115, width: 125, height: 55 },

    { opt: 'R Shoulder', val: 'RF_SHOULDER', top: 102, left: 80, width: 60, height: 85 },
    { opt: 'L Shoulder', val: 'LF_SHOULDER', top: 102, left: 220, width: 60, height: 85 },
    { opt: 'R Arm', val: 'RF_ARM', top: 187, left: 40, width: 75, height: 110 },
    { opt: 'L Arm', val: 'LF_ARM', top: 187, left: 240, width: 75, height: 110 },
    { opt: 'R Wrist', val: 'RF_WRIST', top: 292, left: 10, width: 40, height: 20 },
    { opt: 'L Wrist', val: 'LF_WRIST', top: 292, left: 300, width: 40, height: 20 },
    { opt: 'R Hand', val: 'RF_HAND', top: 297, left: 25, width: 60, height: 75 },
    { opt: 'L Hand', val: 'LF_HAND', top: 297, left: 270, width: 60, height: 75 }   
];
var backAreas = [];
backAreas['m'] = [
    { opt: 'Head', val: 'B_HEAD', top: 7, left: 142, width: 70, height: 95 },
    { opt: 'Upper Back', val: 'B_UPPER_BACK', top: 102, left: 132, width: 80, height: 70 },
    { opt: 'Lower Back', val: 'B_LOWER_BACK', top: 172, left: 127, width: 100, height: 90 },
    { opt: 'Rear', val: 'B_REAR', top: 262, left: 117, width: 120, height: 90 },
    { opt: 'Hamstrings', val: 'B_HAMSTRINGS', top: 352, left: 117, width: 120, height: 90 },
    { opt: 'Knees', val: 'B_KNEES', top: 442, left: 117, width: 120, height: 60 },
    { opt: 'Calves', val: 'B_CALVES', top: 502, left: 117, width: 120, height: 90 },
    //        { opt: 'Ankles', val: 'B_ANKLES', top: 602, left: 117, width: 120, height: 25 },
    { opt: 'Feet', val: 'B_FEET', top: 592, left: 117, width: 120, height: 55 },

    { opt: 'R Shoulder', val: 'RB_SHOULDER', top: 102, left: 72, width: 60, height: 80 },
    { opt: 'L Shoulder', val: 'LB_SHOULDER', top: 102, left: 212, width: 60, height: 80 },
    { opt: 'R Elbow', val: 'RB_ELBOW', top: 182, left: 30, width: 75, height: 110 },
    { opt: 'L Elbow', val: 'LB_ELBOW', top: 182, left: 250, width: 75, height: 110 },
    { opt: 'R Wrist', val: 'RB_WRIST', top: 292, left: 10, width: 40, height: 20 },
    { opt: 'L Wrist', val: 'LB_WRIST', top: 292, left: 300, width: 40, height: 20 },
    { opt: 'R Hand', val: 'RB_HAND', top: 312, left: 0, width: 60, height: 70 },
    { opt: 'L Hand', val: 'LB_HAND', top: 312, left: 290, width: 60, height: 70 }
];
backAreas['f'] = [
    { opt: 'Head', val: 'B_HEAD', top: 7, left: 140, width: 74, height: 95 },
    { opt: 'Upper Back', val: 'B_UPPER_BACK', top: 102, left: 132, width: 80, height: 90 },
    { opt: 'Lower Back', val: 'B_LOWER_BACK', top: 192, left: 127, width: 100, height: 110 },
    { opt: 'Rear', val: 'B_REAR', top: 302, left: 112, width: 130, height: 95 },
    { opt: 'Hamstrings', val: 'B_HAMSTRINGS', top: 397, left: 117, width: 120, height: 40 },
    { opt: 'Knees', val: 'B_KNEES', top: 437, left: 117, width: 120, height: 60 },
    { opt: 'Calves', val: 'B_CALVES', top: 497, left: 117, width: 120, height: 90 },
    //        { opt: 'Ankles', val: 'B_ANKLES', top: 602, left: 117, width: 120, height: 25 },
    { opt: 'Feet', val: 'B_FEET', top: 587, left: 117, width: 120, height: 55 },

    { opt: 'R Shoulder', val: 'RB_SHOULDER', top: 102, left: 72, width: 60, height: 80 },
    { opt: 'L Shoulder', val: 'LB_SHOULDER', top: 102, left: 212, width: 60, height: 80 },
    { opt: 'R Elbow', val: 'RB_ELBOW', top: 182, left: 45, width: 75, height: 110 },
    { opt: 'L Elbow', val: 'LB_ELBOW', top: 182, left: 235, width: 75, height: 110 },
    { opt: 'R Wrist', val: 'RB_WRIST', top: 292, left: 10, width: 40, height: 20 },
    { opt: 'L Wrist', val: 'LB_WRIST', top: 292, left: 300, width: 40, height: 20 },
    { opt: 'R Hand', val: 'RB_HAND', top: 295, left: 5, width: 60, height: 75 },
    { opt: 'L Hand', val: 'LB_HAND', top: 295, left: 280, width: 60, height: 75 }
];

module.exports = function () {

    var _this = this;
	var rec = JSON.parse(sessionStorage.getItem('arg'));
    var ver = rec.version.substr(0, 1).toLowerCase();

    Diver
        .a('corpse')
        .b('body')
        .c('header')
        .d('title')
        .e('titleText').html(rec.title)
        .e('back')
        .e('exit')
        .c('front');
    for (var i = 0; i < frontAreas[ver].length; i++)
        Diver.d(frontAreas[ver][i].val + ' area');
    Diver.c('rear');
    for (var i = 0; i < backAreas[ver].length; i++)
        Diver.d(backAreas[ver][i].val + ' area');

    _this.div = Diver.pop();

    _this.div.back.on('click', function () {
        Router.back();
    });

    _this.div.exit.on('click', function () {
        Router.route('start');
    });

    _this.div.area.on('click', function (ctrl) {
        var area = ctrl.currentTarget.classList[0].replace(/-/g, '_');
        rec.area = area;
        sessionStorage.setItem('arg', JSON.stringify(rec));
        if (area == 'F_HEAD' || area == 'B_HEAD')
            Router.route('head');
        else
            Router.route('choice');
    });

    this.layout = function () {
        var d = _this.div;
        d.style(Design.page);
        d.title.style(Design.title);
        d.titleText.style(Design.titleText);
        d.back.style(Design.back);
        d.exit.style(Design.exit);

        var h = 768 - 65;//Design.hRatio * 654 * 1.3;
        var w = 350 / 654 * h;
        var wRatio = w / 350;
        var hRatio = h / 654;
        var img = 'sc/' + ver + '_';

        d.front.style({ top: Design.top, left: Design.pageWidth / 2 - Design.margin - w, image: img + 'bodyFront', position: 'absolute', width: w, height: h, 'background-color': '#2a394c', 'border-radius': 35, 'z-index': 20 });
        for (var i = 0; i < frontAreas[ver].length; i++) {
            var area = frontAreas[ver][i];
            d[area.val].style({ top: area.top * hRatio, left: area.left * wRatio, position: 'absolute', width: area.width * wRatio, height: area.height * hRatio, 'z-index': 30, image: 'nothing', });
        }

        d.rear.style({ top: Design.top, left: Design.pageWidth / 2 + Design.margin, image: img + 'bodyBack', position: 'absolute', width: w, height: h, 'background-color': '#2a394c', 'border-radius': 35, 'z-index': 20 });
        for (var i = 0; i < backAreas[ver].length; i++) {
            var area = backAreas[ver][i];
            d[area.val].style({ top: area.top * hRatio, left: area.left * wRatio, position: 'absolute', width: area.width * wRatio, height: area.height * hRatio, 'z-index': 30, image: 'nothing', });
        }
    }
}