var Diver = require('diver');
var Remote = require('remote');
var Router = require('router');
var Design = require('design');

module.exports = function () {

    var _this = this;
    var rec = JSON.parse(sessionStorage.getItem('arg'));

    Diver
        .a('split')
        .b('body')
        .c('header')
        .d('title')
        .e('titleText').html(rec.title)
        .e('back')
        .e('exit')
        .c('content')
        .d('mental')
        .e('mentalImage')
        .e('mentalText fldText')
        .d('medical')
        .e('medicalImage')
        .e('medicalText fldText')
        .d('request')
        .e('requestImage')
        .e('requestText fldText');

    _this.div = Diver.pop();

    Remote.get('/services/' + rec.key, function (info) {
        _this.services = info;
        var sc = info.find(o => o.key == 'orssc');
        _this.div.mentalText.html(sc.messages.mental);
        _this.div.medicalText.html(sc.messages.medical);
        _this.div.requestText.html(sc.messages.medicalRequest);
        _this.layout();
    });

    this.div.back.on('click', function () {
        Router.route('menu');
    });
    this.div.exit.on('click', function () {
        Router.route('start');
    });
    this.div.mental.on('click', function () {
        rec.area = 'M_MENTAL';
        sessionStorage.setItem('arg', JSON.stringify(rec));
        Router.route('choice');
    });
    this.div.body.on('click', function () {
        sessionStorage.setItem('arg', JSON.stringify(rec));
        Router.route('body');
    });
    this.div.request.on('click', function () {
        rec.area = 'M_REQUEST';
        rec.service = _this.services.find(o => o.key == 'orsmr');
        sessionStorage.setItem('arg', JSON.stringify(rec));
        Router.route('area');
    });

    this.layout = function () {
        var d = _this.div;
        d.style(Design.page);
        d.title.style(Design.title);
        d.titleText.style(Design.titleText);
        d.back.style(Design.back);
        d.exit.style(Design.exit);
        //d.content.style(Design.content);

        var img = 'sc/' + rec.version.substr(0, 1).toLowerCase() + '_';

        var fieldW = (Design.width - Design.margin * 3) / 3;
        var jump = fieldW + Design.margin * 2;
        var fieldH = Design.height - Design.margin;
        var top = Design.hdrHeight + Design.margin;

        d.mental.style(Design.fieldStyle, { top: top, left: Design.margin, width: fieldW, height: fieldH });
        d.mentalImage.style({ position: 'relative', top: '10%', left: '7%', image: img + 'mental', width: '90%', height: '47%' });
        d.medical.style(Design.fieldStyle, { top: top, left: fieldW + Design.margin * 2, width: fieldW, height: fieldH });
        d.medicalImage.style({ position: 'relative', top: '5%', left: '5%', image: img + 'body', width: '88%', height: '80%' });
        d.request.style(Design.fieldStyle, { top: top, left: fieldW * 2 + Design.margin * 3, width: fieldW, height: fieldH });
        d.requestImage.style({ position: 'relative', top: '25%', left: '5%', image: img + 'request', width: '88%', height: '35%' });
        d.fldText.style({ position: 'absolute', top: '85%', text_align: 'center', width: fieldW, color: 'white' });
    }

    _this.layout();
}