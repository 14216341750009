var $ = require('jquery');

module.exports = new function() {
    var _this = this;
    function switchProps(props, obj){
        for(var key in props){
            if(obj[key] !== undefined){
                var val = obj[key];
                delete obj[key];
                obj[props[key]] = val;
            }
        }
    }

    this.animate = function(elem, options){
        elem.style({
            '-webkit-transform': 'translateZ(0)'
        })
        if(options.delay !== undefined){
            var delay = options.delay;
            setTimeout(function(){
                delete options.delay;
                _this.animate(elem, options);
            }, delay * 1000);
            return {
                after: function(callback){
                    setTimeout(callback, (delay+ options.duration)*1000+75);
                }
            }
        }
        switchProps({
            x: 'left',
            y: 'top',
            w: 'width',
            h: 'height'
        }, options);
        var dur = options.duration || 0;
        var transition = options.transition || 'default';
        delete options.transition;
        delete options.duration;
        var tranString = '';
        elem.style({transition_timing_function: transition});
        for(var key in options){
            tranString += key + ' ' + dur + 's, ';
        }
        tranString = tranString.substr(0, tranString.length-2);
        elem.style({transition: tranString});
        setTimeout(function(){
            for(var key in options){
                elem.style(options);
            }
        }, 50);
        setTimeout(function(){
            elem.style({transition: 'none'});  
        },dur * 1000 + 75);
        return {
            after: function(callback){
                setTimeout(callback, dur*1000+75);
            }
        }
    }
    
    function isArray(elem){
        return Object.prototype.toString.call(elem) == '[object Array]'
    }
    
    this.unfade = function(elem, duration){
        if(isArray(elem)){
            elem.map(function(e){
                _this.unfade(e, duration);
            })
            return;
        }
        if(duration === undefined) duration = .5;
        return this.animate(elem, {
            opacity: 1,
            duration: duration
        })
    }
    
    this.fade = function(elem, duration){
        if(isArray(elem)){
            elem.map(function(e){
                _this.fade(e, duration);
            });
            return;
        }
        if(duration === undefined) duration = .5; 
        return this.animate(elem, {
           opacity: 0,
           duration: duration
        });
    }
    
    this.scroll = function(axis, offset, duration){
        var anim = {};
        anim[axis == 'vertical' ? 'scrollTop':'scrollLeft'] = offset;
        $('html, body').stop().animate(anim, duration);
    }
    
    this.translate = function(elem, x0,y0,x, y, duration){
        var options = {
            duration: duration
        };
        if(x0 !== null) elem.style({left: x0});
        if(y0 !== null) elem.style({top: y0});
        if(x !== null) options.x = x;
        if(y !== null) options.y = y;
        return this.animate(elem, options);
    }
    
    this.translateTo = function(elem, x, y, duration){
        return this.translate(elem, null, null, x, y, duration);
    }
}