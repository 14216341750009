var Diver = require('diver');
var Remote = require('remote');
var Router = require('router');
var Design = require('design');

module.exports = function () {

    var _this = this;
    var rec = JSON.parse(sessionStorage.getItem('arg'));

    Remote.setToken(true);  // fake login
    Diver.setImageLocation('images/');

    Diver
        .a('menu')
        .b('body')
        .c('header')
        .d('title')
        .e('titleText').html(rec.title)
        .e('no911')
        .e('back')
        .e('exit')
        .c('content')
        .c('disclaimer').html(rec.disclaimer);

    _this.div = Diver.pop();

    this.div.back.on('click', function () {
        sessionStorage.setItem('arg', JSON.stringify(rec));        
        Router.route('login');
    });

    this.div.exit.on('click', function () {
        Router.route('start');
    });

    Remote.get('/services/' + rec.key, function (info) {
        _this.services = info;
        loadServices();
    });

    function loadServices(sel) {
        _this.svclist = [];
        Diver.a('content');
        for (var i = 0; i < _this.services.length; i++) {
            var svc = _this.services[i]; // rule? <= 14 char OR < 3 balnks??
            if ((!sel && svc.menu == true) || (sel && sel.indexOf(svc.key) > -1)) {
                Diver.d('I' + i + ' group').e((svc.service.length <= 14) ? 'text1' : 'text2').html(svc.service);
                _this.svclist.push(i);
            }
        }
        _this.list = Diver.pop();
        $(_this.list.group).click(exit);
        _this.div.content.empty();
        _this.div.content.append(_this.list);       
        _this.layout();
    }

    function exit(ctrl) {
        var idx = ctrl.currentTarget.classList[0].substr(1);
        var svc = _this.services[Number(idx)];
        if (svc.services && svc.services.length > 0){
            loadServices(svc.services);
        }
        else {
            rec.service = svc;
            sessionStorage.setItem('arg', JSON.stringify(rec));                    
            Router.route(svc.route);
        }
    }

    this.layout = function () {
        var d = _this.div;
        d.style(Design.page);
        d.title.style(Design.title);
        d.titleText.style(Design.titleText);
        d.back.style(Design.back);
        d.exit.style(Design.exit);
        d.disclaimer.style({ position: 'absolute', top: Design.pageHeight - Design.page.font_size, left: 0, width: Design.width, text_align: 'center' });

        if (_this.list) {
            var rowCnt = Math.ceil(_this.svclist.length / Design.colMax);
            var topMargin = (Design.pageHeight - (rowCnt * Design.grid)) / 2;
            for (var r = 0; r < rowCnt; r++) {
                var inRow = Math.min(_this.svclist.length - r * Design.colMax, Design.colMax);
                var leftMargin = (Design.pageWidth - (inRow * Design.grid)) / 2;
                var listidx = 0;
                for (var c = 0; c < inRow; c++) {
                    //var idx = r * Design.colMax + c;
                    var idx = _this.svclist[listidx++];
                    var svc = _this.services[idx];
                    var b = _this.list['I' + idx];
                    var pos = {
                        position: 'absolute',
                        top: (r * Design.grid) + topMargin,
                        left: (c % 4 * Design.grid) + leftMargin
                    };
                    //top: (r * Design.grid) + topMargin,
                    //left: (c % 4 * Design.grid) + leftMargin};
                    if (svc.image) pos.image = svc.image;
                    b.style(Design.tile, pos);                    
                }
            }

            if (_this.list.text1) _this.list.text1.style({ margin_top: (Design.tileSize - 30) / 2 });
            if (_this.list.text2) _this.list.text2.style({ margin_top: (Design.tileSize - 30) / 2 - 15 });
        }
    }
}
