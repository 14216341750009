var Diver = require('diver');
var Remote = require('remote');
var Router = require('router');
var Design = require('design');
var $ = require('jquery');
var itm = null;

module.exports = function () {
    
    var _this = this;
	var rec = JSON.parse(sessionStorage.getItem('arg'));
    var timeout;
    var interval;

    Diver
        .a('deliver')
        .b('body')
        .c('header')
        .d('title')
        .e('titleText').html(rec.title)
        .e('back')
        .e('exit')
        .c('content')
        .d('arrow #arrow')
        .d('image #image')
        .d('open #open')
        .d('closed #closed')
        .d('nurse')
        .d('touch');

    _this.div = Diver.pop();

    _this.div.back.on('click', function () {
        clearInterval(interval);
        clearTimeout(timeout);
        Router.back();
    });

    _this.div.exit.on('click', function () {
        clearInterval(interval);
        clearTimeout(timeout);
        Router.route('start');
    });

    _this.div.arrow.on('click', deliver);
    _this.div.open.on('click', deliver);
    _this.div.touch.on('click', deliver);

    function deliver() {

        var data = {};
        data.facility = rec.facility;
        data.unit = rec.unit;
        data.type = rec.type;
        data.data = {};
        data.data.inmateId = rec.id;
        data.data.inmateName = rec.name;
        if (rec.dob) data.data.dob = rec.dob;
        data.data.additional = decodeURIComponent(rec.service.messages.additional);
        data.data.image = rec.image;
        data.data.language = rec.key;
        data.data.protocol = rec.protocol;
        data.data.version = rec.version;
        data.tags = rec.tags;

        Remote.sendData('/request', 'POST', data, function (response) {
            clearInterval(interval);
            $('#image').fadeOut(1000);
            $('#open').fadeOut(100);
            $('#closed').fadeIn(100);

            var start = Design.left + 2 * Design.tileSize;
            var end = Design.left + 3.5 * Design.tileSize;
            var int = setInterval(function () {
                start += 15;
                if (start < end) {
                    var nxt = $('#closed');
                    $(nxt).css('left', start);
                    $('#closed').replaceWith(nxt);
                }
                else if (start >= end * 2) {
                    clearInterval(int);
                    Router.route('start');
                }
            }, 50);
        }
            , true, 'Error sending sick call request.');
    }

    this.layout = function () {
        var d = this.div;
        d.title.style(Design.title);
        d.titleText.style(Design.titleText);
        d.back.style(Design.back);
        d.exit.style(Design.exit);

        var msgTop = Design.pageHeight / 2.5;

        d.image.style({ top: msgTop, left: Design.left, image: rec.image, position: 'absolute', width: Design.tileSize, height: Design.tileSize });
        d.arrow.style({ top: msgTop, left: Design.left + Design.tileSize, image: 'ksk/arrow', position: 'absolute', width: Design.tileSize, height: Design.tileSize });
        d.open.style({ top: msgTop + Design.tileSize / 3, left: Design.left + 2 * Design.tileSize, image: 'ksk/msgopen', position: 'absolute', width: .75 * 156, height: .75 * 121 });
        d.closed.style({ top: msgTop + Design.tileSize / 3, left: Design.left + 2 * Design.tileSize, image: 'ksk/msgclosed', position: 'absolute', width: .75 * 156, height: .75 * 121 });
        d.nurse.style({ top: msgTop, left: Design.left + 3 * Design.tileSize, image: 'ksk/nurse', position: 'absolute', width: Design.tileSize, height: Design.tileSize });
        d.touch.style({ top: msgTop + Design.tileSize / 2, left: Design.left + Design.tileSize, image: 'ksk/touch', position: 'absolute', width: 100, height: 100 });
        $('#closed').fadeOut(0);

        // d.image.style({ top: Design.top, left: Design.left, image: 'sc/' + rec.image, position: 'absolute', width: Design.tileSize, height: Design.tileSize });
        // d.arrow.style({ top: msgTop, left: Design.left, image: 'arrow', position: 'absolute', width: Design.tileSize, height: Design.tileSize });
        // d.open.style({ top: msgTop + .5 * 121, left: Design.left + Design.tileSize, image: 'msgopen', position: 'absolute', width: .75 * 156, height: .75 * 121 });
        // d.closed.style({ top: msgTop + .5 * 121, left: Design.left + Design.tileSize, image: 'msgclosed', position: 'absolute', width: .75 * 156, height: .75 * 121 });
        // d.nurse.style({ top: msgTop, left: Design.left + 3 * Design.tileSize, image: 'nurse', position: 'absolute', width: Design.tileSize, height: Design.tileSize });
        // d.touch.style({ top: msgTop + Design.tileSize, left: Design.tileSize / 3, image: 'touch', position: 'absolute', width: 100, height: 100 });
        // $('#closed').fadeOut(0);

        interval = setInterval(function () {
            $('#arrow').fadeOut(1000);
            $('#arrow').fadeIn(1000);
        }, 100);
    }
}