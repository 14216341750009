var Remote = require('remote');

module.exports = new function () {

    window.Router = this;
    var _this = this;
    var stack = [];
    var login;

    _this.init = function (log) {
        login = log;
    }

    _this.route = function (rt) {
        var args = rt.split('|');
        var command = args[0];
        var arg = args[1] || JSON.stringify(login);
        if (command == 'login' && login.ID) command = 'menu';

        var routes = {
            start: function () {
                var Start = require('./views/start');
                var start = new Start(arg);
                $('#page').html(start.div);
                start.layout();
            },

            login: function () {
                var Login = require('./views/login');
                var login = new Login(arg);
                $('#page').html(login.div);
                login.layout();
            },

            menu: function () {
                var Menu = require('./views/menu');
                var menu = new Menu(arg);
                $('#page').html(menu.div);
                menu.layout();
            },

            area: function () {
                var Area = require('./views/area');
                var area = new Area(arg);
                $('#page').html(area.div);
                area.layout();
            },

            sickCall: function () {
                var Split = require('./views/sc/split');
                var split = new Split(arg);
                $('#page').html(split.div);
                split.layout();
            },

            head: function () {
                var Head = require('./views/sc/head');
                var head = new Head(arg);
                $('#page').html(head.div);
                head.layout();
            },

            body: function () {
                var Body = require('./views/sc/body');
                var body = new Body(arg);
                $('#page').html(body.div);
                body.layout();
            },

            deliver: function () {
                var UPS = require('./views/sc/deliver');
                var ups = new UPS(arg);
                $('#page').html(ups.div);
                ups.layout();
            },

            additional: function () {
                var Add = require('./views/sc/additional');
                var add = new Add(arg);
                $('#page').html(add.div);
                add.layout();
            },

            choice: function () {
                var Choice = require('./views/sc/choice');
                var choice = new Choice(arg);
                $('#page').html(choice.div);
                choice.layout();
            },

            past: function () {
                var Past = require('./views/past');
                var past = new Past(arg);
                $('#page').html(past.div);
                past.layout();
            }
        }
        if (routes[command]) {
            var ret = routes[command]();
            stack.push(command);
        }
        else console.log('route unrecognized:' + command);
    }

    _this.back = function (arg) {
        stack.pop();            // get rid of the current route
        //var args = stack.pop();
        _this.route(stack.pop());
    }
    function idleLogout() {
        var t;
        window.onload = resetTimer;
        window.ontouchstart = resetTimer; // catches touchscreen swipes as well 
        window.onclick = resetTimer;      // catches touchpad clicks as well
        window.onkeypress = resetTimer;   
    
        function done() {
            _this.route('start');
        }
    
        function resetTimer() {
            clearTimeout(t);
            t = setTimeout(done, 2*60*1000);  // time is in milliseconds
        }
    }
    idleLogout();    
}