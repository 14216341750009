var Diver = require('diver');
var Remote = require('remote');
var Router = require('router');
var Design = require('design');

module.exports = function () {

    var _this = this;
	var rec = JSON.parse(sessionStorage.getItem('arg'));
    var next = [];
    next.push({ area: rec.area, idx: 0 });

    Diver
        .a('choice')
        .b('body')
        .c('header')
        .d('title')
        .e('titleText').html(rec.title)
        .e('back')
        .e('exit')
        .b('content')
        .b('trailer')
        .c('more');

    _this.div = Diver.pop();

    _this.div.back.on('click', function () {
        if (next.length > 2) {
            next.pop();
            next.pop();
            loadItems();
        }
        else
            Router.back();
    });
    _this.div.exit.on('click', function () {
        Router.route('start');
    });
    _this.div.more.on('click', function () {
        loadItems();
    });

    function shuffle(array) {
        var currentIndex = array.length, temporaryValue, randomIndex;
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    }

    function loadItems() {
        var page = next[next.length - 1];
        if (page.area.substr(0, 1) == 'L' || page.area.substr(0, 1) == 'R') page.area = page.area.substr(1);

        Remote.get('/items/' + page.area + '/' + page.idx + '/' + rec.version, function (info) {
            _this.items = shuffle(info);
            Diver.a('content');
            for (var i = 0; i < _this.items.length; i++) {
                Diver.b('I' + i + ' image');
            }
            _this.choices = Diver.pop();
            $(_this.choices.image).click(deliver);
            $(_this.div).children('.content').replaceWith(_this.choices);
            //_this.div.content.inject(_this.choices);

            Remote.get('/countItems/' + page.area + '/' + (page.idx + 1) + '/' + rec.version, function (info) {
                if (info > 0)
                    next.push({ area: page.area, idx: page.idx + 1 });
                else if (page.area == 'M_MENTAL' || page.area == 'P_PLUS' || page.area == 'M_REQUEST')
                    next.push(null);
                else
                    next.push({ area: 'P_PLUS', idx: 0 });

                _this.layout();
            });
        });
    };

    function deliver(ctrl) {
        var idx = ctrl.currentTarget.classList[0].substr(1);
        var row = _this.items[Number(idx)];
        rec.type = 'orssc';
        rec.image = 'sc/' + row.image;
        rec.protocol = row.protocol;
        rec.tags = [];
        rec.tags.push('orssc');
        rec.tags.push(rec.area);
        var idx = rec.area.indexOf('_');
        rec.tags.push(rec.area.substr(0,idx).toLowerCase());
        rec.tags.push(rec.area.substr(idx+1).toLowerCase());        
        sessionStorage.setItem('arg', JSON.stringify(rec));
        Router.route('additional');
    }

    _this.layout = function () {
        var d = _this.div;
        if (!d) return;
        d.style(Design.page);
        d.title.style(Design.title);
        d.titleText.style(Design.titleText);
        d.back.style(Design.back);
        d.exit.style(Design.exit);
        if (_this.items) {
            var len = _this.items.length;
            if (next[next.length - 1])
                d.more.style(Design.more);
            else if (d.more)
                d.more.style({ display: 'none' });

            var spread = Design.tileSize + 10;
            var rowCnt = Math.min(Math.floor(len / 2) + (((len % 2) > 0) ? 1 : 0), 2);
            var topMargin = (Design.pageHeight - Design.hdrHeight - (rowCnt * Design.grid)) / 2;
            for (var r = 0; r < rowCnt; r++) {
                var inRow = Math.min(len - r * Design.colMax, Design.colMax);
                var leftMargin = (Design.pageWidth - (inRow * spread)) / 2;
                for (var c = 0; c < inRow; c++) {
                    var i = r * 4 + c;
                    var img = _this.items[i];
                    var idiv = _this.choices['I' + i];
                    idiv.style({
                        top: (r * spread) + Design.top,//topMargin,
                        left: (c % 4 * spread) + leftMargin,
                        image: 'sc/' + img.image,
                        position: 'absolute',
                        width: Design.tileSize, height: Design.tileSize,
                        border_radius: 15,
                        box_shadow: 'inset 0 0 45px rgba(0,0,0,.1)'
                    });
                }
            }
        }
    }

    loadItems();
}