var Diver = require('diver');
var Design = require('design');
var cb = null;
var lng = '';

module.exports = new function () {

    var _this = this;
    var _valid = [];

    _this.focus = function (t) {
        currentElement = $(t);
        show();
    }

    _this.generateKeyboard = function (type, rec, callBack) {

        cb = callBack;
        lng = rec.key;
        snd = (rec.service && rec.service.messages.send) ? rec.service.messages.send : '';
        var board = type == 'full' ? full : kiss;

        Diver.a('keyboard')
        generateKeys('board upBoard', board.start);
        generateKeys('board lowBoard', board.lower);
        generateKeys('board numBoard', board.number);
        generateKeys('board symBoard', board.symbols);
        _this.kb = Diver.pop();

        _this.kb.key.on('click', function (ele) {
            var d = _this.kb;
            var cn = ele.target.className;
            var cnS = cn.split(' ');
            if (cnS[1][0] == 'K')
                cb(ele.target.innerText);
            else if (cnS[1] == 'space')
                cb(' ');
            else if (cnS[1] == 'enter')
                cb('send');
            else if (cnS[1] == 'del')
                cb('del');
            else {
                d.board.style({ display: 'none' });
                d[cnS[1] + 'Board'].style({ display: 'block' });
            }
        });

        _this.layout(board.width);
        return _this.kb;

        function generateKeys(brd, keys) {
            Diver.b(brd);

            for (var i = 0; i < keys.length; i++) {
                Diver.c('row');
                for (var j = 0; j < keys[i].length; j++) {
                    var key = keys[i][j];
                    if (key[lng] || key.value) {
                        var vl = key[lng] ? key[lng] : key.value;
                        var txt = (vl == 10004 && rec.send) ? rec.send : ((typeof (vl) == 'number') ? String.fromCharCode(vl) : vl);
                        var cl = 'key ' + ((typeof (key.type) == 'undefined') ? ('K'+txt) : key.type);
                        Diver.d(cl.trim()).html(txt);
                        if (key.value) _valid.push(key.value);
                    }
                }
            }
        }
    }

    _this.invalid = function(charCode){
        if (_valid.indexOf(charCode) == -1) return true;
    }

    _this.layout = function (w) {
        var d = _this.kb;

        d.keyboard.style({
            width: w,
            //left: Design.left,
            text_align: 'center',
            margin: 0,
            display: 'block',
            'user-select': 'none',
            'webkit-user-select': 'none',
            border: '1px solid #E4E4E4', border_radius: 3,
            background_color: '#756C5D'
        });

        d.upBoard.style({ display: 'block' });
        d.lowBoard.style({ display: 'none' });
        d.numBoard.style({ display: 'none' });
        d.symBoard.style({ display: 'none' });

        d.row.style({ line_height: 1 });

        d.key.style({
            width: Design.keySize,
            height: Design.keySize,
            line_height: '60px',
            background_color: '#fff',
            //position: 'relative',
            display: 'inline-block',
            margin: 5,
            cursor: 'pointer',
            font_size: 26,//60,
            border_radius: 8
        });

        if (d.red) d.red.style({ background_color: '#FE2E2E' });
        if (d.grn) d.grn.style({ background_color: '#01DF01', font_weight: 900 });

        if (d.wide) d.wide.style({ width: Design.keyWide });
    }
}

var full = {
    width: Design.width,
    start: [
        // 1st row        
        [{ value: 81 }, { value: 87 }, { value: 69 }, { value: 82 }, { value: 84 }, { value: 89 }, { value: 85 }, { value: 73 },
        { value: 79 }, { value: 80 }],
        // 2nd row
        [{ value: 65 }, { value: 83 }, { value: 68 }, { value: 70 }, { value: 71 }, { value: 72 }, { value: 74 }, { value: 75 },
        { value: 76 }, { ES: 209 }, { value: 9003, type: 'del red' }],
        // 3rd row
        [{ value: 'abc', type: 'low' }, { value: '123', type: 'num' }, { value: '#%+', type: 'sym' }, { value: 90 }, { value: 88 }, { value: 67 }, { value: 86 }, { value: 66 },
        { value: 78 }, { value: 77 }, { EN: 'space', ES: 'blanco', PT: 'branco', type: 'space wide' }],
    ],
    lower: [
        // 1st row
        [{ value: 113 }, { value: 119 }, { value: 101 }, { value: 114 }, { value: 116 }, { value: 121 }, { value: 117 }, { value: 105 },
        { value: 111 }, { value: 112 }],
        // 2nd row
        [{ value: 97 }, { value: 115 }, { value: 100 }, { value: 102 }, { value: 103 }, { value: 104 }, { value: 106 }, { value: 107 },
        { value: 108 }, { lng: 'ES', value: 241 }, { value: 9003, type: 'del red' }],
        // 3rd row
        [{ value: 'ABC', type: 'up' }, { value: '123', type: 'num' }, { value: '#%+', type: 'sym' }, { value: 122 }, { value: 120 }, { value: 99 }, { value: 118 }, { value: 98 },
        { value: 110 }, { value: 109 }, { EN: 'space', ES: 'blanco', PT: 'branco', type: 'space wide' }],
    ],
    number: [
        // 1st row
        [{ value: 49 }, { value: 50 }, { value: 51 }, { value: 52 }, { value: 53 }, { value: 54 }, { value: 55 }, { value: 56 }, { value: 57 },
        { value: 48 }],
        // 2nd row
        [{ value: 45 }, { value: 47 }, { value: 58 }, { value: 59 }, { value: 40 }, { value: 41 }, { value: 36 }, { value: 38 }, { value: 64 }, { value: 34 },
        { value: 9003, type: 'del red' }],
        //3rd row
        [{ value: 'ABC', type: 'up' }, { value: 'abc', type: 'low' }, { value: '#%+', type: 'sym' }, { value: 63 }, { value: 33 }, { value: 92 },
        { value: 42 }, { value: 61 }, { value: 43 }, { EN: 'space', ES: 'blanco', PT: 'branco', type: 'space wide' }],
    ],
    symbols: [
        // 1st row
        [{ value: 91 }, { value: 93 }, { value: 123 }, { value: 125 }, { value: 35 }, { value: 37 }, { value: 94 }, { value: 42 }, { value: 43 },
        { value: 61 }],
        // 2nd row
        [{ value: 95 }, { value: 92 }, { value: 126 }, { value: 60 }, { value: 62 }, { value: 46 }, { value: 44 }, { value: 63 }, { value: 9003, type: 'del red' }],
        // 3rd row
        [{ value: 'ABC', type: 'up' }, { value: 'abc', type: 'low' }, { value: '123', type: 'num' }, { value: 33 }, { value: 39 }, { value: 34 }, { value: 59 }, { value: 47 },
        { value: 44 }, { value: 46 }, { EN: 'space', ES: 'blanco', PT: 'branco', type: 'space wide' }],
    ]
}

var kiss = {
    width: Design.keypadWidth,
    start: [
        [{ value: 49 }, { value: 50 }, { value: 51 }],
        [{ value: 52 }, { value: 53 }, { value: 54 }],
        [{ value: 55 }, { value: 56 }, { value: 57 }],
        [{ value: 48 }, { value: 9003, type: 'del red' }]
    ],
    lower: [],
    number: [],
    symbols: []
}
