var Diver = require('diver');
var Animate = require('animate');

module.exports = new function(){
    
    Diver
    .a('notification');
    
    this.div = Diver.pop();
    var _this = this;
    
    
    function showMessage(type, message){
        if(message[message.length-1] !== '.') message += ".";
        Diver
        .a('message')
            .b('type').html(type)
            .b('message').html(message)
            .b('close');
        var div = Diver.pop();
        
        function layout(){
            
            var background = {
                success: '#6bb746',
                error: '#d14129',
                warning: '#e2ca2c',
                note: '#BBBBBB'
            }[type];
            
            div.style({
                width: window.innerWidth,
                height: 50,
                background: background,
                position: 'fixed',
                top: -50,
                left: 0,
                opacity: 0,
                z_index: 5
            });
            
            div.type.style({
                font_size: 26,
                line_height: 1,
                position: 'absolute',
                top: 12,
                left: 12,
                font_weight: 500,
                letter_spacing: -2,
                color: 'rgba(0,0,0,.2)',
                text_transform: 'uppercase'
            });
            
            div.message.style({
                font_size: 17,
                color: '#FFF',
                line_height: 50/17,
                text_align: 'center',
                
            })
            
            div.close.style({
                position: 'absolute',
                width: 24,
                height: 24,
                border_radius: 3,
                image: 'close_clear',
                left: window.innerWidth - 37,
                top: 13,
                cursor: 'pointer'
            })
        }
        layout();
        
        Animate.animate(div,{
            top: 0,
            opacity: 1,
            duration: .75
        });
        
        var open = true;
        function close(){
            if(!open) return;
            open = false;
            Animate.animate(div,{
                top: -50,
                opacity: 0,
                duration: .75
            });
            setTimeout(function(){
                div.remove();
            },1000);
        }
        
        setTimeout(close, {
            error: 7500,
            success: 3500,
            warning: 4500,
            note: 4500
        }[type]);
        
        
        _this.div.append(div);
        
    }
    
    this.success = function(message){
        showMessage('success', message);
    }
    
    this.error = function(message){
        showMessage('error', message);
    }
    
    this.warning = function(message){
        showMessage('warning', message);
    }
    
    this.notify = function(message){
        showMessage('note', message)
    }
    
    this.layout = function(){
        this.div.style({
            position: 'absolute',
            top: 0,
            left: 0,
            z_index: 4
        });
    }
    
    this.layout();
}