var Diver = require('diver');
var Remote = require('remote');
var Router = require('router');
var Design = require('design');
var Keyboard = require('keyboard');

module.exports = function () {

    var _this = this;
    var rec = JSON.parse(sessionStorage.getItem('arg'));
    _this.service = rec.service;
    var stack = [];

    Diver
        .a('areas')
        .b('body')
        .c('header')
        .d('title')
        .e('titleText').html(rec.title)
        .e('back')
        .e('exit')
        .c('content')
        .d('trail')
        .e('serviceText subBox')
        .e('areaText subBox')
        .e('itemText subBox')
        .d('list')
        .c('trailer')
        .d('more');

    _this.div = Diver.pop();

    function loadItems() {

        _this.div.serviceText.html(_this.service.service);

        var stk = stack[stack.length - 1];
        if (stk.arr) {
            //if (stk.key != 'none') lines[stack.length] = stk.text;

            stk.end = stk.idx;
            var idx = 0;
            Diver.a('list');
            //while (stk.end < stk.arr.length && (top < (Design.pageHeight - Design.hdrHeight - h))) {
            while (stk.end < stk.arr.length) {
                var itm = stk.arr[stk.end];
                if (idx++ % 4 == 0) Diver.b('boxes');
                if (itm.image)
                    Diver.c('subBox /img #I' + stk.end, { src: 'images/' + itm.image + '.png' });
                else
                    Diver.c('subBox #I' + stk.end).html(itm.text);
                stk.end++;
            }
            _this.list = Diver.pop();
            _this.list.style({ position: 'absolute', top: (Design.height - 2 * Design.boxWidth) / 2 });
            _this.list.boxes.style(Design.boxes);
            _this.list.subBox.style(Design.subBox);
            $(_this.list.subBox).click(onward);
            $(_this.div).find('.list').replaceWith(_this.list);
        } else {
            var addH = 4 * Design.keySize;
            var addT = Design.keyTop - addH + Design.keySize - Design.margin;

            Diver.a('list')
                .b('additional').html(_this.service.messages.more)
                .b('enter')
                .c('chr').html(String.fromCharCode(10004))
                .b('keyboard');
            _this.list = Diver.pop();
            _this.kb = Keyboard.generateKeyboard('full', rec, keyClick);
            $(_this.list).find('.keyboard').replaceWith(_this.kb);
            $(_this.div).find('.list').replaceWith(_this.list);
            _this.list.additional.style({ position: 'absolute', top: addT, height: addH, width: Design.width - Design.keySize - Design.margin, font_size: 26, border: '1px solid #E4E4E4', border_radius: 8, background_color: '#FFF' });
            _this.list.enter.style({ position: 'absolute', top: addT, height: addH, width: Design.keySize, left: Design.width - Design.keySize, background_color: '#01DF01', font_size: 26, border: '1px solid #E4E4E4', border_radius: 8 });
            _this.list.chr.style({ position: 'relative', top: '50%', text_align: 'center', transform: 'translateY("-50%")' });
            _this.kb.style({ position: 'absolute', top: Design.keyTop + Design.keySize });
            _this.list.enter.on('click', deliver);
            document.addEventListener('keydown', keyClick);
        }
        _this.layout();
    }

    var txt = '';
    var maxLength = 200;

    function keyClick(char) {
        if (typeof char == 'object') char = char.key;
        if (char == 'Shift') return;
        if (char == 'Enter') char = 'send';
        if (char == 'Backspace') char = 'del';        
        if (char == 'send') deliver();
        else if (char == 'del') {
            if (txt.length > 0) {
                var len = txt.length - 1;
                if (txt.substr(len, 1) == '>') len = txt.lastIndexOf('<');
                txt = txt.substr(0, len);
                _this.list.additional.html(txt);
            }
        }
        else if (txt.length < maxLength) {
            txt += char;
            _this.list.additional.html(txt);
        }
    }
    function deliver() {
        rec.type = _this.service.key;
        rec.service.messages.additional = txt;
        rec.tags = [];
        for (var i = 0; i < stack.length; i++) {
            var s = stack[i];
            if (s.img) rec.image = s.img;
            rec.tags.push(s.key);
        }
        sessionStorage.setItem('arg', JSON.stringify(rec));
        Router.route('deliver');
    }

    _this.div.back.on('click', function () {
        if (stack.length > 1) {
            stack.pop();
            loadItems();
        }
        else {
            var d = _this.div;
            d.areaText.style({ display: 'none' });
            d.itemText.style({ display: 'none' });
            Router.back();
        }
    });

    _this.div.exit.on('click', function () {
        Router.route('start');
    });

    _this.div.more.on('click', function () {
        var stk = stack[stack.length - 1];
        stack.push({ idx: stk.end, arr: stk.arr, key: stk.key });
        loadItems();
    });

    function onward(ctrl) {
        var stk = stack[stack.length - 1].arr[ctrl.currentTarget.id.substr(1)];
        //stack[stack.length - 1].text = (stk.key != 'none') ? stk.text : null;
        stack[stack.length - 1].img = stk.image ? stk.image : null;
        stack.push({ idx: 0, arr: stk.item, key: stk.key, text: stk.text });
        loadItems();
    }

    _this.layout = function () {
        var d = _this.div;
        d.title.style(Design.title);
        d.titleText.style(Design.titleText);
        d.trail.style({
            display: 'flex',
            justify_content: 'flex-begin',
            flex_direction: 'row',
            margin: Design.margin,
            width: Design.pageWidth - 2 * Design.margin,
            overflow: 'hidden'
        });
        d.subBox.style(Design.subBox);
        d.back.style(Design.back);
        d.exit.style(Design.exit);
        d.content.style({ position: 'absolute', top: Design.top, left: Design.left, width: Design.pageWidth - 2 * Design.left });

        if (stack.length >= 2 && stack[1].key != 'none') {
            d.areaText.html(stack[1].text);
            if (stack.length == 3 && stack[2].key != 'none')
                d.itemText.html(stack[2].text);
            else
                d.itemText.style({ display: 'none' });
        } else {
            d.areaText.style({ display: 'none' });
            d.itemText.style({ display: 'none' });
        }

        var stk = stack[stack.length - 1];
        if (stk && stk.arr && stk.end < stk.arr.length)
            d.more.style(Design.more);
        else
            d.more.style({ display: 'none' });
    };

    stack.push({ idx: 0, arr: _this.service.item, key: _this.service.key });
    loadItems();
}