module.exports = new function() {
    
    function pad(n, width, z) {
      z = z || '0';
      n = n + '';
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }
    
    var _this = this;
    
    this.timeToDate = function(time){
        var date = new Date(time);
        return date.toLocaleDateString('en-US');
    }
    
    this.dobToAge = function(dob){
        var year = parseInt(dob.substr(0,4));
        var month = parseInt(dob.substr(5,2));
        var day = parseInt(dob.substr(8,2));
        var d = new Date();
        var years = d.getFullYear() - year;
        var past = (d.getMonth()+1 > month) ||
            (d.getMonth()+1 == month && d.getDate()>= day);
        var age = years + (past ? 0 : -1);
        return age;
    }

    this.timeToLocal = function(time){
        return time + (new Date().getTimezoneOffset() * 60000);
    }
    
    this.currentYMDDate = function () {
        var dt = new Date();
        var y = dt.getFullYear();
        var m = dt.getMonth() + 1;
        var d = dt.getDate();
        
        return y + '-' + pad(m, 2) + '-' + pad(d, 2);
    }
    
    this.toYMDDate = function (time) {
        var dt = new Date(time);
        var y = dt.getFullYear();
        var m = dt.getMonth() + 1;
        var d = dt.getDate();
        
        return y + '-' + pad(m, 2) + '-' + pad(d, 2);
    }

    this.currentTime = function(){
        return this.toTime(new Date());
    }
    
    this.toTime = function(date){
        return date.toLocaleTimeString().replace(/:\d+ /, ' ');
    }

    this.timeInputToMili = function(str){
        var hours = str.substr(0,2);
        var minutes = str.substr(3,2);
        return (hours * 3600000) + (minutes * 60000);
    }

    this.timeToDateTime = function(time){
        var date = new Date(time);
        var now = new Date();
        var d = this.timeToDate(time);
        var slash = d.lastIndexOf('/');
        var year = d.substr(slash+1,4);
        if( parseInt(year) == now.getFullYear()) d = d.substr(0,slash);
        var t = date.toLocaleTimeString('en-US');
        var mili = t.lastIndexOf(':');
        t = t.substr(0,mili) + ' '+ t.substr(mili+4,2);
        return d + ' ' + t 
    }
    
    this.shortDate = function(time){
        var date = new Date(time);
        return (date.getMonth()+1)+'/'+pad(date.getDate(),2);
    }

    this.shortDateYear = function(time){
        var date = new Date(time);
        return (date.getMonth()+1)+'/'+pad(date.getDate(),2)+'/'+pad(date.getYear()-100,2);
    }
    
    this.shortTime = function(time){
        var date = new Date(time);
        var suffix = date.getHours() > 11 ? 'PM':'AM';
        var hours = date.getHours() % 12;
        hours = hours == 0 ? 12 : hours;
        return hours + ':'+ pad(date.getMinutes(),2) + ' ' + suffix;
    }
    
    this.toInputValue = function(time){
        var d = new Date();
        if(time == undefined) time = Date.now();
        time = Math.floor(time/60000)*60000;
        time = time - d.getTimezoneOffset()* 60000;
        var date = new Date(time);
        return date.toISOString().replace('Z','');
    }
    
    this.toDateInput = function(time){
        var string = this.toInputValue(time);
        var t = string.indexOf('T');
        return string.substr(0,t);
    }

    this.toTimeInput = function(time){
        var string = this.toInputValue(time);
        var t = string.indexOf('T');
        return string.substr(t+1);
    }

    this.datetimeInputToUTC = function(val){
        var time = Date.parse(val);
        var d = new Date();
        time = time + d.getTimezoneOffset() * 60000;
        return time;
    }
    
    this.shortDateTime = function(time){
        return this.shortDate(time) + ' ' + this.shortTime(time);
    }
    
    this.idToUTC = function(id){
        return parseInt(id.substring(0, 8), 16) * 1000;
    }
    
    this.idToDate = function(id){
        return this.timeToDate(this.idToUTC(id));
    }
    
    this.idToDateTime = function(id){
        return this.timeToDateTime(this.idToUTC(id));
    }
    this.idToTime = function(id){
        var date = new Date(this.idToUTC(id));
        return this.toTime(date);
    }
    
    window.DateTime = this;
    
}