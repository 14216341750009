var Diver = require('diver');
var Remote = require('remote');
var Router = require('router');
var Design = require('design');

module.exports = function () {

    var _this = this;
    sessionStorage.setItem('arg', '');

    Diver
        .a('start')
        .b('body')
        .c('header')
        .d('title')
        .e('titleText')
        .c('content')
        .c('version').html('&#169; 2018&nbsp;&nbsp;1.3.0');

    _this.div = Diver.pop();

    Remote.sendData('/configuration', 'GET', null, function (info) {
        _this.languages = info.languages;
        var title = '';
        Diver.a('content');
        for (var i = 0; i < _this.languages.length; i++) {
            title += (i == 0 ? '':' / ') + _this.languages[i].title;
            Diver.b('I' + i + ' choice').html(_this.languages[i].display);
        }
        _this.list = Diver.pop();
        _this.div.titleText.html(title);
        $(_this.list.choice).click(exit);
        $(_this.div.content).replaceWith(_this.list);
        _this.layout();
    }, true);

    var exit = function (ctrl) {
        var idx = ctrl.currentTarget.classList[0].substr(1);
        sessionStorage.setItem('arg', JSON.stringify(_this.languages[Number(idx)]));
        Router.route('login');
    }

    _this.language = { cursor: 'pointer', font_size: 30, width: 200, height: 60, text_align: 'center', margin_left: 'auto', margin_right: 'auto', color: '#000', background_color: '#756c5d', border: '1px solid #E4E4E4', border_radius: 3 };

    _this.layout = function () {

        var d = _this.div;
        d.style(Design.page);
        d.title.style(Design.title);
        d.titleText.style(Design.titleText);
        d.version.style({ font_size: 30, color: 'black', position: 'absolute', top: Design.pageHeight - 34, left: 0, width: 200, height: 20 });

        if (_this.list) {
            var itms = _this.languages;

            // var rowCnt = Math.ceil(itms.length / Design.colMax);
            // var topMargin = (Design.pageHeight - 50 - (rowCnt * Design.grid)) / 2;
            // for (var r = 0; r < rowCnt; r++) {
            //     var inRow = Math.min(itms.length - r * Design.colMax, Design.colMax);
            //     var leftMargin = (Design.pageWidth - (inRow * Design.grid)) / 2;
            //     for (var c = 0; c < inRow; c++) {
            //         var lng = itms[r * Design.colMax + c];
            //         var b = _this.list[lng.key];
            //         var pos = {
            //             position: 'absolute', 
            //             top: (r * Design.grid) + topMargin,
            //             left: (c % 4 * Design.grid) + leftMargin,
            //             line_height: '185px'};
            //         b.style(Design.tile, pos);                  
            //     }
            // }
            var topMargin = 200;
            var leftMargin = (Design.pageWidth - Design.tileSize) / 2;
            for (var i = 0; i < _this.languages.length; i++) {
                var b = _this.list['I' + i];
                var pos = {
                    position: 'absolute',
                    top: (i * (_this.language.height + 10)) + topMargin,
                    left: leftMargin,
                    line_height: '63px'
                };
                b.style(_this.language, pos);
            }

            $(window).css({
                transform: "translate(-50%, -50%) " + "scale(" + Design.scale + ")"
            });
        }
    }
}