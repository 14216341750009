var $ = require('jquery');
module.exports = new function(){
        
    var alphabet = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
    var jqObject = $('<div />');
    
    var _str = '';
    var _closings = [];
    var _level = -1;
    var _this = this;
    
    if(typeof console === 'undefined') console = {log: function(){}};
    
    function clip(str) { return str.substr(1, str.length - 1) }
    
    function explodeAddArguments(args){
        var returnArgs = [];
        for(var i = 1; i < args.length; i++) {
            $.merge(returnArgs, typeof args[i] == 'string' ? args[i].split(' ') : [args[i]]);
        }
        return $.merge([args[0]], returnArgs);
    }
    
    function add() {
        
        var args = explodeAddArguments(arguments);
        var elementType = 'div';
        var id = null;
        var level = args[0];
        var attributes = null;
        var classes = [];
        
        if(level <= _level) {
            for(var i = 0; i < _level+1 - level; i++) _str += _closings.pop();
        }
        for(var i = 1; i < args.length; i++){
            var arg = args[i];
            if (typeof arg != "string") {
                attributes = arg;
            }
            else if (arg.indexOf('/') === 0) elementType = clip(arg);
            else if (arg.indexOf('#') === 0) id = clip(arg);
            else {
                if(!jqObject[arg] && !nodeFunctions[arg]) {
                    classes.push(arg);
                } else {
                    console.log('DIVER ALERT: '+arg+' is a jQuery/Diver object property/function, class ignored');
                }
            }
        }
        _str += '<'+elementType;
        if(classes.length > 0) {
            _str+= ' class="';
            for(var i = 0; i < classes.length; i++){
                var c = classes[i];
                c = c.replace(/_/g, '-');
                _str += i ===0 ? c : ' '+c;
            }
            _str += '"';
        }
        if(id) _str += ' id="'+id+'"';
        if(attributes) {
            for(var key in attributes) _str += ' ' + key + '="' + attributes[key]+'" ';
        }
        _str += ">";
        _level = level;
        _closings.push('</'+elementType+'>');
        return _this;
    }
    for(var i = 0; i < alphabet.length; i++) addLetter(i);
    function addLetter(i){
        _this[alphabet[i]] = function() { return add.apply(this, $.merge([i], arguments));}
    }
    this.html = function(str){
        _str += str;
        return _this;
    }
    this.pop = function(){
        while(_closings.length > 0) _str += _closings.pop();
        var ret = hookupClasses($(_str));
        reset();
        return ret;
        
    }
    this.popOn = function(parent){
        parent = parent instanceof $ ? parent : $(parent);
        var child = _this.pop();
        parent.append(child);
        return child;
    }
    this.div = function(){
        return this.a.apply(this, arguments).pop();
    }
    function reset(){
        _classes = {};
        _str = '';
        _closings = [];
        _level = -1;
    }
    
    var nodeFunctions = {
        style: function() {
           // if(applyToNodes(this,nodeFunctions.style,arguments)) return;
            if(arguments.length == 1){
                var sheet = arguments[0];
                var freshSheet = {};
                for(var key in sheet) freshSheet[key] = sheet[key];
                if(this._diverScale == undefined) this._diverScale = 1;
                var processed = processSheet.call(this, freshSheet,this._diverScale);
                ensureBoxModel(this, processed);
                var domSheet = {};
                if(this._diverStyle === undefined) this._diverStyle = {};
                for(key in processed){
                    if(this._diverStyle[key] != processed[key]){
                        domSheet[key] = processed[key];
                        this._diverStyle[key] = processed[key];
                    } 
                }
               
                this.css(domSheet);
            } else {
                for(var i = 0; i < arguments.length; i++) nodeFunctions.style.call(this,arguments[i]);
            }
        },
        scale: function(factor) {
            if(this._diverScale == undefined) this._diverScale = 1;
            if(this._diverScale != factor){
                this._diverScale = factor / this._diverScale;
                nodeFunctions.style.call(this, this._diverStyle);
                this._diverScale = factor;
            }
        },
        changeState: function(state){
            this._diverState = state;
            if(this._diverStateStyles && this._diverStateStyles[state]) nodeFunctions.style.call(this, this._diverStateStyles[state]);
            if(this._diverStateChanges && this._diverStateChanges[state]) this._diverStateChanges[state]();
        },
        styleState: function(state, style){
            if(!this._diverStateStyles) this._diverStateStyles = {};
            this._diverStateStyles[state] = style;
            if(this._diverState == state) nodeFunctions.style.call(this, this._diverStateStyles[state]);
        },
        onState: function(state, func){
            if(!this._diverStateChanges) this._diverStateChanges = {};
            this._diverStateChanges[state] = func;
        },
        boxModel: function(type){
           this._diverBoxModel = type;
        }
        
    }
    
    function ensureBoxModel(node, sheet){
       var model = node._diverBoxModel;
       if(model !== undefined && !(sheet.width === undefined && sheet.height === undefined)){
            if(model == 'border') sheet['box-sizing'] = 'border-box';
            if(model == 'content') sheet['box-sizing'] = 'content-box';
       } 
    }
    
    function hookupClasses(cluster) {
        var nodeByClass = {};
        var allNodes = [];
        function createNode(){
            var node = $(this);
            allNodes.push(node);
            if(node.attr('class')){
                node._classes = [];
                var classes = node.attr('class').split(' ');
                for(var i = 0; i < classes.length; i++){
                    var tag = classes[i].replace(/-/g, '_');
                    if(nodeByClass[tag]) {
                        cluster[tag] = cluster[tag].add(this);
                        nodeByClass[tag].push(node);
                    } else {
                        cluster[tag] = node;
                        nodeByClass[tag] = [node];
                    }
                    node._classes.push(tag);
                }
            }
            return node;
        }
        createNode.apply(cluster[0]);
        cluster.find('*').each(createNode);
        
        function addInject(obj){
            obj.inject = function(){
                var a = arguments;
                for(var i = 0; i < a.length; i++){
                    function prepareDiv(){
                        var node = createNode.call(this);
                        if(node._classes) node._classes.map(setUpChild);
                    }
                    prepareDiv.apply(a[i]);
                    $(a[i]).find('*').each(prepareDiv);
                    
                }
                obj.append.apply(this, a);
            } 
        }
        addInject(cluster);

        for(var key in nodeByClass) setUpChild(key);
        function setUpChild(key){
            var nodes = nodeByClass[key];
            var child = cluster[key];
            if(nodes.length > 1){
                child._diverNodes = [];
                for(var i = 0; i < nodes.length; i++){
                    child._diverNodes.push(nodes[i]);
                }
                child.eq = function(i) { 
                    ensureFuncs(this._diverNodes[i]);
                    return this._diverNodes[i];
                }
                child.map = function(func) {
                    for(var i = 0; i < this._diverNodes.length; i++){
                        ensureFuncs(this._diverNodes[i]);
                        func(this._diverNodes[i], i);
                    }
                }                
            }
            addInject(child);
            for(var func in nodeFunctions) addNodeFunction(child, func, nodes);
            function ensureFuncs(node){
                if(!node._divered){
                    for(var func in nodeFunctions) addNodeFunction(node, func, [node]);
                }
                node._divered = true;
            }
            function addNodeFunction(child, func, nodeArr){
                child[func] = function() {
                    for(var i = 0; i < nodeArr.length; i++){
                        nodeFunctions[func].apply(nodeArr[i], arguments);
                    }
                    
                }
            }
        }
        
        for(var func in nodeFunctions) addClusterFunc(func);
        function addClusterFunc(func){
            cluster[func] = function(){
                nodeFunctions[func].apply(allNodes[0], arguments);
                if(func == 'scale' || func == 'boxModel') {
                    for(var i = 1; i < allNodes.length; i++){
                        nodeFunctions[func].apply(allNodes[i], arguments);
                    }
                }
            }
        }
        cluster._diverClusterNodes = allNodes;
        
        return cluster;
    }
    
   
    
    var _imageLocation = '';
    var _imageExtension;
    this.setImageLocation = function(loc){ _imageLocation = loc }
    this.setDefaultImageExtension = function(ext) {
        ext = ext.replace('.','');
        _imageExtension = '.'+ext;
    }
    
    
    var scaleParams = [
        'top','left',
        'width','height',
        'margin','margin-left','margin-top','margin-right','margin-bottom',
        'padding','padding-left','padding-top','padding-right','padding-bottom',
        'font-size', 'letter-spacing', 'word-spacing',
        'border-width','border-bottom-width','border-top-width','border-left-width','border-right-width','border-radius'
    ];
    var shouldScale = {};
    for(var param in scaleParams) shouldScale[scaleParams[param]] = true;
    
    var _styleShortcuts = {
        image : function(sheet, scale, value){
            sheet['background-image'] = 'url('+_imageLocation + value + (_imageExtension || '') +')';
            sheet['background-size'] = '100% 100%';
        },
        shadow: function(sheet, scale, value){
            var shadow = '0px 0px '+(value[0]*scale)+'px '+(value[1]*scale)+'px rgba(0,0,0,'+value[2]+')';
            sheet['-moz-box-shadow'] = shadow;
            sheet['-webkit-box-shadow'] = shadow;
            sheet['box-shadow'] = shadow;
        },
        box: function(sheet, scale, value){
            sheet.left = value[0] * scale;
            sheet.top = value[1] * scale;
            sheet.width = value[2] * scale;
            sheet.height = value[3] * scale;
            if(sheet.position === undefined) sheet.position = 'absolute';
        },
        gradient: function(sheet, scale, value){
            var grad = 'linear-gradient('+value[0]+', '+value[1]+')';
            sheet.background = grad;
        }
    }
    this.addStyleShortCut = function(key, func){
        _styleShortcuts[key] = func;
    }
    
    
    function processSheet(sheet, scale){
       
        for(var key in sheet){
            var dashed = key.replace(/_/g, '-');
            if(key != dashed) {
                sheet[dashed] = sheet[key];
                delete sheet[key];
            }
        }
        for(var param in shouldScale){
            if(sheet[param] != undefined && typeof sheet[param] != 'string' ) {
                sheet[param] = sheet[param] * scale;
            }
        }
        for(var shortcut in _styleShortcuts) {
            if(sheet[shortcut] !== undefined){
                _styleShortcuts[shortcut](sheet, scale, sheet[shortcut]);
                delete sheet[shortcut];
            }
        }
        return sheet;
        
    }
    
    
}