module.exports = new function () {

    //_this.pageWidth = 910; //securus II
    //_this.pageHeight = 480;
    //_this.pageWidth = 1024; //keefe
    //_this.pageHeight = 768;
    var _this = this;

    _this.pageWidth = 1024;//window.innerWidth - 2;//.innerWidth;//Design.width;
    _this.pageHeight = 768;//window.innerHeight - 2;//.innerHeight;// Design.height;

    _this.hRatio = 1;//_this.pageHeight / 1480;
    _this.wRatio = 1;//_this.pageWidth / 720;

    _this.background = '#384456';//'#e9e9e9';//'#5d6675';// '#384456'//#AFD3ED'
    _this.hdrHeight = 50;
    _this.trlrHeight = 55;
    _this.top = 60;
    _this.margin = 15;

    _this.page = { position: 'absolute', top: 0, left: 0, width: _this.pageWidth, height: _this.pageHeight, background_color: _this.background, font_size: 40, letter_spacing: -1, line_height: 1 };
    _this.content = { position: 'absolute', top: _this.top, left: _this.margin, width: _this.pageWidth - 2 * _this.margin, height: _this.pageHeight - _this.margin - _this.top, background_color: '#2a394c', border_radius: 8 };
    _this.back = { position: 'absolute', top: 0, left: 0, image: 'ksk/back', width: _this.hdrHeight, height: _this.hdrHeight };
    _this.enter = { position: 'absolute', top: 0, left: 0, image: 'ksk/back', width: _this.hdrHeight, height: _this.hdrHeight };
    _this.title = { position: 'absolute', top: 0, left: 0, width: _this.pageWidth, height: _this.hdrHeight, image: 'ksk/headband', 'z-index': 1 };
    _this.titleText = { position: 'absolute', top: (_this.top - _this.hdrHeight) / 2, left: _this.hdrHeight + _this.margin, color: '#FFF' };
    _this.exit = { position: 'absolute', top: 0, left: _this.pageWidth - _this.hdrHeight, image: 'ksk/exit', width: _this.hdrHeight, height: _this.hdrHeight };

    _this.left = 16;
    _this.keyWide = 100;
    _this.colMax = 4;
    _this.rowMax = 2;
    _this.boxWidth = 140;

    _this.keySize = _this.pageWidth / 15;//18;//60;
    _this.keyHeight = _this.keySize * 5 + 40;
    _this.keyTop = _this.pageHeight - _this.keyHeight;
    _this.areaTop = _this.keySize * 3;
    _this.keypadTop = _this.areaTop + _this.keySize + _this.margin;
    _this.keypadWidth = _this.keySize * 4;
    _this.width = _this.pageWidth - (2 * _this.margin);
    _this.height = _this.pageHeight - _this.hdrHeight - (2 * _this.margin);
    _this.tileSize = Math.min((_this.pageWidth - (_this.colMax + 1) * _this.margin) / _this.colMax, (_this.pageHeight - _this.top - _this.rowMax * _this.margin) / _this.rowMax)//170;//185;
    _this.tile = { cursor: 'pointer', font_size: 30, width: _this.tileSize, height: _this.tileSize, text_align: 'center', color: '#000', background_color: '#756c5d', border: '1px solid #E4E4E4', border_radius: 3 };
    _this.grid = _this.tileSize + _this.margin;
    _this.more = { display: 'inline', position: 'absolute', top: _this.pageHeight - _this.trlrHeight, left: _this.pageWidth - _this.trlrHeight, image: 'plus', width: _this.hdrHeight, height: _this.hdrHeight };
    _this.subText = { width: _this.width, font_size: 30, color: 'white' };//'#756c5d'};
    _this.choices = { display: 'flex', justify_content: 'center', align_items: 'center', width: _this.pageWidth, margin_top: _this.margin };//'#756c5d'};
    _this.item = { cursor: 'pointer', font_size: 30, width: _this.width - _this.margin, letter_spacing: -1, line_height: '40px', color: '#000', background_color: '#756C5D', border: '1px solid #E4E4E4', border_radius: 3 };

    _this.boxes = { display: 'flex', justify_content: 'center', flex_direction: 'row', margin: _this.margin, width: _this.pageWidth };
    _this.trail = { display: 'flex', justify_content: 'flex-begin', flex_direction: 'row', margin: _this.margin, width: _this.pageWidth - 2*_this.margin, overflow: 'hidden' };
    _this.subBox = { margin: 16, display: 'flex', justify_content: 'center', align_items: 'center', text_align: 'center', width: _this.boxWidth, height: _this.boxWidth, font_size: 20, color: 'white', background_color: '#203864' };

    //_this.subBoxes = { display: 'flex', flex_direction: 'row', margin: 12, justify_content: 'center', align_items: 'center'};//'#756c5d'};
    //_this.subBox = { display: 'inline_block', margin: 12, width: _this.boxWidth, height: _this.boxWidth, font_size: 20, color: 'white', 'background-color': '#2a394c'};
    //_this.subBox = { text_align: 'center', margin: _this.margin, width: _this.boxWidth, height: _this.boxWidth, font_size: 20, color: 'white', 'background-color': '#2a394c'};

    _this.status = { height: 80, line_height: '80px' };
    _this.send = { cursor: 'pointer', width: 200, font_size: 30, line_height: '50px', height: 50, background_color: '#756C5D', border: '1px solid #E4E4E4', border_radius: 3 };
    _this.sendArrow = { cursor: 'pointer', width: 100, height: 44, image: 'ksk/send', background_color: '#756C5D' };

    _this.fieldStyle = { position: 'absolute', 'z-index': 10, 'background-color': '#2a394c', 'border-radius': 35, box_shadow: 'inset 0 0 45px rgba(0,0,0,.1)' };
}