var Diver = require('diver');
var Remote = require('remote');
var Router = require('router');
var Design = require('design');
var DateTime = require('date');

module.exports = function () {

    var _this = this;
	var rec = JSON.parse(sessionStorage.getItem('arg'));
    var stack = [];
    var listTop = Design.keySize * 3;

    Diver
        .a('areas')
        .b('body')
        .c('header')
        .d('title')
        .e('titleText').html(rec.title)
        .e('back')
        .e('exit')
        .c('content')
        .d('serviceText subText').html(rec.serviceText)
        .d('list')
        .c('trailer')
        .d('more');

    _this.div = Diver.pop();

    Remote.get('/services/all', function (info) {
        _this.services = info;
        _this.div.serviceText.html(rec.service.service);
        Remote.get('/requests/all/all/' + rec.inmateId, function (info) {
            _this.requests = info;
            stack.push({ idx: 0, arr: _this.requests });
            loadItems();
        });
    });

    function loadItems() {

        var stk = stack[stack.length - 1];
        if (!stk.arr || stk.arr.length == 0) {
            Diver.a('list').b('none').html(_this.service.messages.none);
            _this.list = Diver.pop();
            _this.list.style({ position: 'absolute', top: listTop, left: Design.left });
            $(_this.div).find('.list').replaceWith(_this.list);
        }
        else {
            stk.end = stk.idx;
            var top = listTop;
            var h = 50;
            Diver.a('list');
            while (stk.end < stk.arr.length && (top < (Design.pageHeight - Design.hdrHeight - h))) {
                var req = stk.arr[stk.end];
                var service;
                for (var j = 0; j < _this.services.length; j++) {
                    var svc = _this.services[j];
                    if (req.data.language == svc.language && req.data.service == svc.key) {
                        service = svc;
                        if (svc.item && svc.item.length > 0) {
                            for (var k = 0; k < svc.item.length; k++) {
                                if (req.type == svc.item[k].key) {
                                    req.area = svc.item[k].text;
                                    break;
                                }
                            }
                        } else if (req.data.protocol)
                            req.area = req.data.protocol;
                        break;
                    }
                }
                Diver
                    .b('request #R' + stk.end)
                    .c('service').html(service.service + ' / ' + req.area)
                    .c('dt').html(service.messages.sent + ': ' + DateTime.idToDateTime(req._id))
                    .c('status').html(req._status)
                //.c('area').html(area.text);
                stk.end++;
                top += h;
            }
            _this.list = Diver.pop();

            _this.list.request.style({
                font_size: 30,
                width: Design.width,
                margin: Design.margin,
                letter_spacing: -1,
                line_height: '32px',
                color: '#000',
                background_color: '#756C5D',
                border: '1px solid #E4E4E4',
                border_radius: 3,
            });
            // _this.list.dt.style({
            //     display: 'inline-block'
            // });
            // _this.list.status.style({
            //     margin_left: Design.margin,
            //     display: 'inline-block'
            // });

            $(_this.div.content).find('.list').replaceWith(_this.list);
        }
        _this.layout();
    }

    _this.div.back.on('click', function () {
        if (stack.length > 1) {
            stack.pop();
            loadItems();
        }
        else
            Router.back();
    });

    _this.div.exit.on('click', function () {
        Router.route('start');
    });

    _this.div.more.on('click', function () {
        var stk = stack[stack.length - 1];
        stack.push({ idx: stk.end, arr: stk.arr, key: stk.key });
        loadItems();
    });

    _this.layout = function () {
        var d = _this.div;
        d.title.style(Design.title);
        d.titleText.style(Design.titleText);
        d.subText.style(Design.subText);
        d.back.style(Design.back);
        d.exit.style(Design.exit);
        d.content.style({ position: 'absolute', top: Design.top, left: Design.left });

        var stk = stack[stack.length - 1];
        if (stk && stk.arr && stk.end < stk.arr.length)
            d.more.style(Design.more);
        else
            d.more.style({ display: 'none' });
    };
}