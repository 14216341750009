var $ = require('jquery');
var Diver = require('diver');
var Router = require('router');
var Start = require('./views/start');
var Design = require('design');

module.exports = new function () {
    
    Diver.setImageLocation('images/');
    Diver.setDefaultImageExtension('png');
    
    this.init = function (login) {
        window.resizeTo(Design.pageWidth, Design.pageHeight);
        $('html, body').css({
            margin: 0,
            padding: 0,
            background_color: Design.background
        })
        
        var main = Diver.a('main').b('#page').pop();
        $('body').html(main);
        
        function layout() {
            main.style( Design.page);
        }
        
        function resize() {
            var winW = $(window).width() - 17;
            var winH = $(window).height() - 17;
            var wide = (winW / winH) > (w / h);
            var scale = wide ? winH / h : winW / w;
            var offX = wide ? .5 * (winW - (w * scale)):0;
            var offY = wide ? 0 : .5 * (winH - (h * scale));
            var scaleS = 'scale(' + scale + ',' + scale + ')';
            
            main.style({
                transform_origin: 'top left',
                '-ms_transform': scaleS,
                '-webkit_transform': scaleS,
                transform: scaleS,
                position: 'top',
                left: offX,
                top: offY
            });
        }
        layout();
        //resize();
        //$(window).on('resize', resize);
        Router.init(login);              
        Router.route('start');
    }
}