var Diver = require('diver');
var Router = require('router');
var Remote = require('remote');
var Design = require('design');
var Keyboard = require('keyboard');

module.exports = function () {

    var _this = this;
    var rec = JSON.parse(sessionStorage.getItem('arg'));
    var flg;
    var notOK;
    var max;
    var width;
    var txt;

    Diver
        .a('login')
        .b('body')
        .c('header')
        .d('title')
        .e('titleText').html(rec.title)
        .e('back')
        .c('content')
        .d('area')
        .d('keyboard');

    _this.div = Diver.pop();
    _this.kb = Keyboard.generateKeyboard('kiss', rec, keyClick);
    $(_this.div).find('.keyboard').replaceWith(_this.kb);
    document.addEventListener('keydown', keyClick);

    function setupPrompt(type, bad) {
        txt = '';
        flg = type;
        notOK = bad;
        Diver.a('area');

        if (bad) {
            Diver
                .b('message').html(type)
                .b('incorrect');
            _this.area = Diver.pop();
            _this.area.message.style({ position: 'absolute', top: Design.areaTop - Design.keySize, left: 0, width: Design.pageWidth, 'font-size': 100, text_align: 'center', color: 'white' });
            _this.area.incorrect.style({ position: 'absolute', top: Design.areaTop - Design.keySize, left: (Design.pageWidth - 100) / 2, image: 'ksk/incorrect', width: 100, height: 100, opacity: 0.6 });
        }
        else {
            if (type == 'ID') {
                max = 6;
                width = (max + 1) * (Design.keySize + Design.margin);
                Diver.b('prompt').html('ID:');
                for (var i = 0; i < max; i++) {
                    Diver.b('chr id' + i);
                }
            }
            if (type == 'DOB') {
                var mm = rec.key == 'EN' ? 'M' : 'D';
                var dd = rec.key == 'EN' ? 'D' : 'M';
                var yr = rec.key == 'EN' ? 'Y' : 'A';
                max = 8;
                width = (max + 2) * (Design.keySize + Design.margin);
                Diver.b('prompt').html('DOB:')
                Diver.b('chr id0').html(mm)
                    .b('chr id1').html(mm)
                    .b('prompt').html('/')
                    .b('chr id2').html(dd)
                    .b('chr id3').html(dd)
                    .b('prompt').html('/')
                    .b('chr id4').html(yr)
                    .b('chr id5').html(yr)
                    .b('chr id6').html(yr)
                    .b('chr id7').html(yr);
            }
            Diver.b('chr enter').html(String.fromCharCode(10004));
            _this.area = Diver.pop();
            _this.area.prompt.style({ float: 'left', margin_left: 16, margin_top: 15 });
            _this.area.chr.style({ float: 'left', margin_left: 16, margin_top: 4, width: Design.keySize - 8, height: Design.keySize - 8, text_align: 'center', line_height: (Design.keySize - 8) + 'px', background_color: '#FFF', border_radius: 8 });
            _this.area.enter.style({ background_color: '#01DF01' });
            _this.area.style({ position: 'absolute', top: Design.areaTop, left: (Design.content.width - width) / 2, height: Design.keySize, width: width, font_size: 26, border: '1px solid #E4E4E4', border_radius: 8, background_color: '#dee8eb' });//'#756C5D' });
            _this.area.enter.on('click', enterClick);
        }

        $(_this.div).find('.area').replaceWith(_this.area);
        _this.layout();
    }

    this.div.back.on('click', function () {
        if (rec.id) {
            rec.id = null;
            setupPrompt('ID');
        }
        else
            Router.route('start');
    });

    function enterClick(ele) {
        keyClick('send');
    }

    function keyClick(char) {
        if (typeof char == 'object') char = char.key;
        if (char == 'Enter') char = 'send';
        if (char == 'Backspace') char = 'del';
        if (notOK)
            setupPrompt(flg);
        else if (char == 'send' && flg == 'ID') {
            Remote.sendData('/inmate/load/' + txt, 'GET', null, function (info) {
                if (!info || info._status != 'active')
                    setupPrompt('ID', true);
                else {
                    rec.id = info.id;
                    rec.name = info.lastName + ', ' + info.firstName;
                    rec.dob = info.dob;
                    rec.facility = 'DCSO';
                    rec.unit = info.unit;
                    rec.version = (info.sex.substr(0, 1) == 'f') ? 'Female' : 'Male';
                    setupPrompt('DOB');
                }
            }, true);
        }
        else if (char == 'send' && flg == 'DOB') {
            // if (txt.length == 0)
            //     Router.route('menu|' + JSON.stringify(rec));
            if (txt.length == 8) {
                var mm = txt.substr(0, 2);
                var dd = txt.substr(2, 2);
                var yyyy = txt.substr(4);
                if (rec.key != 'EN') {
                    mm = txt.substr(2, 2);
                    dd = txt.substr(0, 2);
                    yyyy = txt.substr(4);
                }
                var d = yyyy + '-' + mm + '-' + dd;
                if (d == rec.dob) {
                    sessionStorage.setItem('arg', JSON.stringify(rec));
                    Router.route('menu');
                }
                else
                    setupPrompt('DOB', true);
            }
            else
                setupPrompt('DOB', true);
        }
        else if (char == 'del') {
            if (txt.length > 0) {
                var key = 'id' + (txt.length - 1);
                _this.area[key].html(' ');
                txt = txt.substr(0, txt.length - 1);
            }
        } else if (txt.length < max) {
            txt += char;
            var key = 'id' + (txt.length - 1);
            _this.area[key].html(char);
        }
    }

    _this.layout = function () {
        var d = _this.div;
        d.style(Design.page);
        d.title.style(Design.title);
        d.titleText.style(Design.titleText);
        d.back.style(Design.back);
        _this.kb.style({ position: 'absolute', top: Design.keypadTop, left: (Design.width - Design.keypadWidth) / 2 });
    }

    setupPrompt('ID');
}