var Diver = require('diver');
var Remote = require('remote');
var Router = require('router');
var Design = require('design');
var Keyboard = require('keyboard');

module.exports = function () {

    var _this = this;
    var rec = JSON.parse(sessionStorage.getItem('arg'));

    var timeout;
    var interval;
    var txt = '';
    var maxLength = 200;

    Diver
        .a('entry')
        .b('body')
        .c('header')
        .d('title')
        .e('titleText').html(rec.title)
        .e('back')
        .e('exit')
        .c('content')
        .d('subText').html('')
        .d('subText').html('')
        .d('subText').html('')
        .d('additional').html(rec.service.messages.more)
        .d('enter')
        .e('chr').html(String.fromCharCode(10004))
        .d('keyboard');

    _this.div = Diver.pop();
    rec.service.messages.send = '&nbsp;'; //no language
    _this.kb = Keyboard.generateKeyboard('full', rec, keyClick);
    $(_this.div).find('.keyboard').replaceWith(_this.kb);

    _this.div.back.on('click', function () {
        Router.back();
    });
    _this.div.exit.on('click', function () {
        Router.route('start');
    });

    _this.div.enter.on('click', deliver);

    function deliver() {
        rec.service.messages.additional = encodeURIComponent(txt);
        sessionStorage.setItem('arg', JSON.stringify(rec));
        Router.route('deliver');
    }

    function keyClick(char) {
        if (char == 'send')
            deliver();
        else if (char == 'del') {
            if (txt.length > 0) {
                var len = txt.length - 1;
                if (txt.substr(len, 1) == '>') len = txt.lastIndexOf('<');
                txt = txt.substr(0, len);
                _this.div.additional.html(txt);
            }
        }
        else if (txt.length < maxLength) {
            txt += char;
            _this.div.additional.html(txt);
        }
    }

    _this.layout = function () {
        var d = _this.div;
        var addH = 4 * Design.keySize;
        var addT = Design.keyTop - addH + Design.keySize - Design.margin;
        d.title.style(Design.title);
        d.titleText.style(Design.titleText);
        d.subText.style(Design.subText);
        d.back.style(Design.back);
        d.exit.style(Design.exit);
        d.content.style({ position: 'absolute', top: Design.top, left: Design.left });        
        d.additional.style({ position: 'absolute', top: addT, height: addH, width: Design.width - Design.keySize - Design.margin, font_size: 26, border: '1px solid #E4E4E4', border_radius: 8, background_color: '#FFF' });
        d.enter.style({ position: 'absolute', top: addT, height: addH, width: Design.keySize, left: Design.width - Design.keySize, background_color: '#01DF01', font_size: 26, border: '1px solid #E4E4E4', border_radius: 8 });
        d.chr.style({position: 'relative', top: '50%', text_align: 'center', transform: 'translateY("-50%")'});
        _this.kb.style({ position: 'absolute', top: Design.keyTop + Design.keySize});
    }
}