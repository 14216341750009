var Diver = require('diver');
var Router = require('router');
var Design = require('design');

var frontAreas = [];
frontAreas['m'] = [
    { opt: 'Scalp', val: 'F_SCALP', top: 20, left: 120, width: 160, height: 50 },
    { opt: 'Forehead', val: 'F_FOREHEAD', top: 70, left: 112, width: 176, height: 60 },
    { opt: 'Eyes', val: 'F_EYES', top: 130, left: 135, width: 140, height: 50 },
    { opt: 'Nose', val: 'F_NOSE', top: 185, left: 173, width: 70, height: 40 },
    { opt: 'Lips', val: 'F_LIPS', top: 225, left: 148, width: 70, height: 40 },
    { opt: 'Chin', val: 'F_CHIN', top: 265, left: 148, width: 70, height: 40 },
    { opt: 'Throat', val: 'F_THROAT', top: 305, left: 115, width: 140, height: 90 },

    { opt: 'R Ear', val: 'RF_EAR', top: 140, left: 80, width: 50, height: 65 },
    { opt: 'L Ear', val: 'LF_EAR', top: 140, left: 278, width: 50, height: 65 },
    { opt: 'R Cheek', val: 'RF_CHEEK', top: 195, left: 135, width: 30, height: 75 },
    { opt: 'L Cheek', val: 'LF_CHEEK', top: 195, left: 245, width: 30, height: 75 },
    { opt: 'R Shoulder', val: 'RF_SHOULDER', top: 305, left: 0, width: 130, height: 90 },
    { opt: 'L Shoulder', val: 'LF_SHOULDER', top: 305, left: 265, width: 130, height: 90 }
];

frontAreas['f'] = [
    { opt: 'Scalp', val: 'F_SCALP', top: 20, left: 120, width: 160, height: 50 },
    { opt: 'Forehead', val: 'F_FOREHEAD', top: 70, left: 112, width: 176, height: 60 },
    { opt: 'Eyes', val: 'F_EYES', top: 130, left: 135, width: 120, height: 50 },
    { opt: 'Nose', val: 'F_NOSE', top: 180, left: 165, width: 70, height: 30 },
    { opt: 'Lips', val: 'F_LIPS', top: 210, left: 165, width: 70, height: 30 },
    { opt: 'Chin', val: 'F_CHIN', top: 240, left: 165, width: 70, height: 30 },
    { opt: 'Throat', val: 'F_THROAT', top: 270, left: 127, width: 140, height: 90 },

    { opt: 'R Ear', val: 'RF_EAR', top: 135, left: 85, width: 50, height: 65 },
    { opt: 'L Ear', val: 'LF_EAR', top: 135, left: 255, width: 50, height: 65 },
    { opt: 'R Cheek', val: 'RF_CHEEK', top: 180, left: 135, width: 30, height: 60 },
    { opt: 'L Cheek', val: 'LF_CHEEK', top: 180, left: 235, width: 30, height: 60 },
    { opt: 'R Shoulder', val: 'RF_SHOULDER', top: 305, left: 0, width: 130, height: 90 },
    { opt: 'L Shoulder', val: 'LF_SHOULDER', top: 305, left: 265, width: 130, height: 90 }
];

var backAreas = []
backAreas['m'] = [
    { opt: 'Head', val: 'B_BACKHEAD', top: 20, left: 115, width: 170, height: 115 },
    { opt: 'Neck', val: 'B_NECK', top: 135, left: 133, width: 130, height: 170 },
    { opt: 'Upper Back', val: 'B_UPPER_BACK', top: 305, left: 133, width: 130, height: 90 },

    { opt: 'R Ear', val: 'RB_EAR', top: 135, left: 80, width: 50, height: 65 },
    { opt: 'L Ear', val: 'LB_EAR', top: 135, left: 268, width: 50, height: 65 },
    { opt: 'R Shoulder', val: 'RB_SHOULDER', top: 305, left: 0, width: 130, height: 90 },
    { opt: 'L Shoulder', val: 'LB_SHOULDER', top: 305, left: 267, width: 130, height: 90 }
];
backAreas['f'] = [
    { opt: 'Head', val: 'B_BACKHEAD', top: 20, left: 105, width: 170, height: 115 },
    { opt: 'Neck', val: 'B_NECK', top: 135, left: 133, width: 115, height: 170 },
    { opt: 'Upper Back', val: 'B_UPPER_BACK', top: 305, left: 123, width: 130, height: 90 },

    { opt: 'R Ear', val: 'RB_EAR', top: 135, left: 80, width: 50, height: 65 },
    { opt: 'L Ear', val: 'LB_EAR', top: 135, left: 253, width: 50, height: 65 },
    { opt: 'R Shoulder', val: 'RB_SHOULDER', top: 305, left: 0, width: 120, height: 90 },
    { opt: 'L Shoulder', val: 'LB_SHOULDER', top: 305, left: 257, width: 120, height: 90 }
];

module.exports = function () {

    var _this = this;
	var rec = JSON.parse(sessionStorage.getItem('arg'));
    var ver = rec.version.substr(0, 1).toLowerCase();

    Diver
        .a('topHead')
        .b('body')
        .c('header')
        .d('title')
        .e('titleText').html(rec.title)
        .e('back')
        .e('exit')
        .c('front');
    for (var i = 0; i < frontAreas[ver].length; i++)
        Diver.d(frontAreas[ver][i].val + ' area');
    Diver.c('rear');
    for (var i = 0; i < backAreas[ver].length; i++)
        Diver.d(backAreas[ver][i].val + ' area');

    _this.div = Diver.pop();

    _this.div.back.on('click', function () {
        Router.back();
    });

    _this.div.exit.on('click', function () {
        Router.route('start');
    });

    _this.div.area.on('click', function (ctrl) {
        var area = ctrl.currentTarget.classList[0].replace(/-/g, '_');
        rec.area = area;
        sessionStorage.setItem('arg', JSON.stringify(rec));
        Router.route('choice');
    });


    this.layout = function () {
        var d = _this.div;
        d.style(Design.page);
        d.title.style(Design.title);
        d.titleText.style(Design.titleText);
        d.back.style(Design.back);
        d.exit.style(Design.exit);

        var h = Design.hRatio * 400;// * 1.3;
        var w = h;
        var wRatio = w / 400;
        var hRatio = h / 400;

        // var hRatio = Design.pageHeight / 768;
        // var wRatio = Design.pageWidth / 1080;
        // var h = hRatio * 400;
        // var w = wRatio * 400;
        var top = Design.hdrHeight + (Design.pageHeight - Design.hdrHeight - h) / 2;
        var img = 'sc/' + ver + '_';

        d.front.style({ top: top, left: Design.pageWidth / 2 - Design.margin - w, image: img + 'headFront', position: 'absolute', width: w, height: h, 'background-color': '#2a394c', 'border-radius': 35, 'z-index': 20 });
        for (var i = 0; i < frontAreas[ver].length; i++) {
            var area = frontAreas[ver][i];
            d[area.val].style({ top: area.top * hRatio, left: area.left * wRatio, position: 'absolute', width: area.width * wRatio, height: area.height * hRatio, 'z-index': 30, image: 'nothing', });
        }

        d.rear.style({ top: top, left: Design.pageWidth / 2 + Design.margin, image: img + 'headBack', position: 'absolute', width: w, height: h, 'background-color': '#2a394c', 'border-radius': 35, 'z-index': 20 });
        for (var i = 0; i < backAreas[ver].length; i++) {
            var area = backAreas[ver][i];
            d[area.val].style({ top: area.top * hRatio, left: area.left * wRatio, position: 'absolute', width: area.width * wRatio, height: area.height * hRatio, 'z-index': 30, image: 'nothing', });
        }
    }
}